/* @format */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

//import Gruppe from "../../../model/Gruppe";
import _ from "lodash-core";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeCommonStyles } from "../../../styles";

const useStyles = makeCommonStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    }
}));

export default function GruppenListe({ gruppen }) {
    const classes = useStyles();
    const [panel, setPanel] = useState();
    const handleChange = useCallback(
        (id) => {
            if (panel === id) {
                setPanel(null);
            } else {
                setPanel(id);
            }
        },
        [panel]
    );

    if (_.isEmpty(gruppen)) {
        return <p>Es sind keine Gruppen für dich hinterlegt.</p>;
    } else {
        return _.map(gruppen, (g, id) => (
            <ExpansionPanel
                key={id}
                className={classes.bottomSpace}
                expanded={panel === id}
                onChange={() => handleChange(id)}
                square={false}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`grouppanel-${id}-content`}
                    id={`grouppanel-${id}-header`}
                >
                    <Typography className={classes.heading}>
                        {g.name}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                        {g.desc}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        Hier kann noch einiges an weiterer INformation hin -
                        vielleicht schon Quicklinks, News Badges,
                        Messageoftheday....
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        ));
    }
}

GruppenListe.propTypes = {
    gruppen: PropTypes.object //arrayOf(Gruppe)
};
GruppenListe.defaultProps = {
    gruppen: null
};
