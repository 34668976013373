import  { connect } from "react-redux";

import _ from "lodash";

import Component from "./component";

const mapStateToProps = (state) => {
    return {
        gruppen: _.get(state, ["gruppen", "data"])
    };
};

export default connect(mapStateToProps)(Component);
