/* @format */
import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import useLoginContext from "@sa/react-login/useLoginContext";

export default function PrivateRoute({
    component: Component,
    location,
    ...rest
}) {
    console.log("THE location", location);
    const loginContext = useLoginContext();
    if (
        (!loginContext || loginContext.isAnonymous()) &&
        (!location || location.pathname !== "/user/login")
    ) {
        const state = { redirect: location.pathname };
        console.log("Navigate to login", state);
        navigate("/user/login", { state });
        return null;
    }
    return <Component {...rest} />;
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    })
};
PrivateRoute.defaultProps = {
    location: null
};
