/* @format */
import React from "react";
import Layout from "../../components/layout";

import { Router } from "@reach/router";

import NotFound from "../../content/NotFound";

import PrivateRoute from "../../components/login/PrivateRoute";

import KlassenMain from "../../content/klassen/Main";
import GruppenListe from "../../content/klassen/GruppenListe";
import Gruppe from "../../content/klassen/Gruppe";

const KlassenPage = () => {
    return (
        <Layout>
            <Router>
                <KlassenMain path="/" />
                <PrivateRoute
                    path="/klassen/meine-gruppen"
                    component={GruppenListe}
                />
                <PrivateRoute path="/klassen/g/:gid" component={Gruppe} />
                <NotFound default />
            </Router>
        </Layout>
    );
};

export default KlassenPage;
