/* @format */
import React from "react";

import Seo from "../../components/seo";
import ToolbarHeader from "../../components/ToolbarHeader";

export default function KlassenMain() {
    return (
        <>
            <Seo title="Virtuelle Klassenräume" />
            <ToolbarHeader title="Virtuelle Klassenräume" />
            <main>Übersicht über alle Klassenräume...</main>
        </>
    );
}
