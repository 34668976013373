/* @format */
import React from "react";

import Seo from "../../components/seo";
import ToolbarHeader from "../../components/ToolbarHeader";
import { useCommonStyles } from "../../styles";

import GruppenListe from "../../components/gruppen/GruppenListe";

export default function MeineGruppenListe() {
    const classes = useCommonStyles();
    return (
        <>
            <Seo title="GruppenListe" />
            <ToolbarHeader title="Deine Gruppen" />
            <main className={classes.content}>
                <GruppenListe />
            </main>
        </>
    );
}
