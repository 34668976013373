/* @format */
import React from "react";
import { Link } from "gatsby-theme-material-ui";

import ToolbarHeader from "../components/ToolbarHeader";

export default function NotFound() {
    return (
        <>
            <ToolbarHeader title="Nirvana...." />
            <main>
                <p>Die gesuchte Seite existiert nicht...</p>
                <Link href="/">Zur Eingangsseite....</Link>
            </main>
        </>
    );
}
