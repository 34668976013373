/* @format */
import React from "react";
import PropTypes from "prop-types";

import Seo from "../../components/seo";
import ToolbarHeader from "../../components/ToolbarHeader";

export default function Gruppe({ gid }) {
    return (
        <>
            <Seo title="Gruppe" />
            <ToolbarHeader title="Eine Gruppe...." />
            <main>Die Gruppe {gid}</main>
        </>
    );
}

Gruppe.propTypes = {
    gid: PropTypes.string.isRequired
};
